import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import PageBanner from '../components/PageBanner';
import Events from '../components/Events';
import { incomingEventStatusName } from '../settings';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageBanner styleId={1} title={"Les évènements"} description={"Venez découvrir de nouveaux sujets autour de la visualisation de données, en présentiel ou à distance ! Vous pouvez revoir les présentations et les vidéos des évènements passés."} mdxType="PageBanner" />
    <section id="one">
      <Events includeStatus={[incomingEventStatusName]} displayVideoFilter={false} wrapForPage={true} hideVideoFilter={true} title={"Les évènements à venir"} mdxType="Events" />
      <div className="inner">
        <Events title={"Les évènements passés"} mdxType="Events" />
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      